import React, { useState } from 'react';

const Input = (props: any) => {
    const [id] = useState(Math.random().toString().split(".")[1]);

    return (
        <div className="form-group">
            <label htmlFor={id} dangerouslySetInnerHTML={{__html: props.label+":"}}></label>
            <div className="input-group mb-3">
                {props.prepend && (
                    <div className="input-group-prepend">
                        <span className="input-group-text">{props.prepend}</span>
                    </div>
                )}
                <input type={props.type || "text"} className="form-control" id={id} value={props.state} onChange={(e) => props.setState(e.target.value)} />
                {props.append && (
                    <div className="input-group-append">
                        <span className="input-group-text">{props.append}</span>
                    </div>
                )}
            </div>
        </div>
    );
}

export default Input;