import React, { useEffect, useState } from 'react';
import firebase from "../../firebase";
import { stats, modes } from "../../enums";
import ListItem from './ListItem';
import { useHistory } from 'react-router-dom';
import Input from '../elements/Input';
import Select from '../elements/Select';

const db = firebase.firestore();

function BatchUpdate() {
    const [packs, setPacks] = useState<any[]>([]);
    const [departTime, setDepartTime] = useState("");
    const [mode, setMode] = useState("");
    const [status, setStatus] = useState("");
    const history = useHistory();
    const [notif, setNotif] = useState("");

    useEffect(() => {
        let q: any = db.collection("packages");

        if (departTime) q = q.where('departTime', '==', departTime);
        else q = q.orderBy('departTime');
        if (mode) q = q.where('mode', '==', mode);

        q = q.limit(200);

        q.get().then((snapshot: any) => {
            setPacks(snapshot.docs);
        });
    }, [departTime, mode]);

    const update = () => {
        var batch = db.batch();

        packs.forEach((pack) => {
            batch.update(db.doc(`/packages/${pack.id}`), { status });
        });

        batch.commit().then(function () {
            history.push("/packages");
        }).catch(console.error);
    }

    const updateNotif = () => {
        var batch = db.batch();

        packs.forEach((pack) => {
            batch.update(db.doc(`/packages/${pack.id}`), { notif });
        });

        batch.commit().then(function () {
            history.push("/packages");
        }).catch(console.error);
    }

    return (
        <>
            <div className="row">
                <div className="col">
                    <Input state={departTime} setState={setDepartTime} label="Гарах огноогоор шүүх" type="date" />
                </div>
                <div className="col">
                    <Select state={mode} setState={setMode} label="Ангилал" options={{ ...modes, "": "Бүгд" }} />
                </div>
            </div>
            <div className="input-group mb-3">
                <select className="custom-select" value={status} onChange={(e) => setStatus(e.target.value)}>
                    <option value="">Шинэчлөх төлөвөө сонгох</option>
                    {Object.keys(stats).map((key) => (
                        <option key={key} value={key}>
                            {stats[key]}
                        </option>
                    ))}
                </select>
                <div className="input-group-append">
                    <button className="btn btn-primary" type="button" disabled={!departTime || !status} onClick={update}>
                        <svg width="1em" height="1em" viewBox="0 0 16 16" className="bi bi-pencil" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                            <path fillRule="evenodd" d="M12.146.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1 0 .708l-10 10a.5.5 0 0 1-.168.11l-5 2a.5.5 0 0 1-.65-.65l2-5a.5.5 0 0 1 .11-.168l10-10zM11.207 2.5L13.5 4.793 14.793 3.5 12.5 1.207 11.207 2.5zm1.586 3L10.5 3.207 4 9.707V10h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.293l6.5-6.5zm-9.761 5.175l-.106.106-1.528 3.821 3.821-1.528.106-.106A.5.5 0 0 1 5 12.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.468-.325z" />
                        </svg>&nbsp;
                        Шинэчлэх
                    </button>
                </div>
            </div>
            <div className="input-group mb-3">
                <input className="form-control" defaultValue={notif} onChange={(e) => setNotif(e.target.value)} placeholder="Тээврийн мэдээлэл шинэчлэх" />
                <div className="input-group-append">
                    <button className="btn btn-primary" type="button" disabled={!notif} onClick={updateNotif}>
                        <svg width="1em" height="1em" viewBox="0 0 16 16" className="bi bi-chat-left-dots" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                            <path fillRule="evenodd" d="M14 1H2a1 1 0 0 0-1 1v11.586l2-2A2 2 0 0 1 4.414 11H14a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1zM2 0a2 2 0 0 0-2 2v12.793a.5.5 0 0 0 .854.353l2.853-2.853A1 1 0 0 1 4.414 12H14a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2z"/>
                            <path d="M5 6a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm4 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm4 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0z"/>
                        </svg>&nbsp;
                        Шинэчлээд мэссэж явуулах
                    </button>
                </div>
            </div>
            <ul className="list-group mb-2">
                {packs.map((pack) => ({ id: pack.id, ...pack.data() })).map((pack) => <ListItem pack={pack} key={pack.id} /> )}
            </ul>
        </>
    );
}

export default BatchUpdate;