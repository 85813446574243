import React, { useContext, useState } from 'react';
import { AuthContext } from '../AuthProvider';

const InputWorker = (props: any) => {
    const {currentUser} = useContext(AuthContext);
    const [id] = useState(Math.random().toString().split(".")[1]);
	
    return (
        <div className="form-group">
            <label htmlFor={id} dangerouslySetInnerHTML={{__html: props.label+":"}}></label>
            <div className="input-group mb-3">
                {props.prepend && (
                    <div className="input-group-prepend">
                        <span className="input-group-text">{props.prepend}</span>
                    </div>
                )}
                <input disabled={["worker","delivery","reporter"].indexOf(currentUser?.role)!== -1} type={props.type || "text"} className="form-control" id={id} defaultValue={props.state} onChange={(e) => props.setState(e.target.value)} />
                {props.append && (
                    <div className="input-group-append">
                        <span className="input-group-text">{props.append}</span>
                    </div>
                )}
            </div>
        </div>
    );
}

export default InputWorker;