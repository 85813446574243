import firebase from 'firebase/app';
import "firebase/analytics";
import "firebase/auth";
import "firebase/firestore";
import "firebase/functions";

const config = {
    apiKey: "AIzaSyAKi0RQj4bEMU_G9keMB6n2kymwo1jlzU4",
    authDomain: "khishigcargo.firebaseapp.com",
    projectId: "khishigcargo",
    appId: "1:231660620830:web:c0baad1c1d4b6bac25b20f",
    measurementId: "G-9F4D0M3WHK"
};

firebase.initializeApp(config);
firebase.analytics();
firebase.auth();
firebase.firestore();
firebase.functions();

export default firebase;