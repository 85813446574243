// Modal.js
 
import React from "react";
import { AuthContext } from "../AuthProvider";
 
const Modal = ({ isOpen, onClose, children } : any) => {
    if (!isOpen) return null;
 
    return (
        <div
            onClick={onClose}
            style={{
                position: "fixed",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
                background: "rgba(0, 0, 0, 0.5)",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                zIndex: 999,
            }}
        >
            <div
                style={{
                    background: "white",
                    maxHeight: "90%",
                    width: 800,
                    margin: "auto",
                    padding: "10px",
                    border: "2px solid #000",
                    borderRadius: "10px",
                    boxShadow: "2px solid black",
                    overflow: "auto", 
                    zIndex: 999
                }}
            >
                {children}
            </div>
        </div>
    );
};
 
export default Modal;