import React, {  } from 'react';
import { Link } from 'react-router-dom';
import { modes } from "../../enums";
import { phoneNumberStyler } from "../../helpers";

function ReceiverListItem(props: any) {
    const { pack, onClick } = props;
		
	let codeColor;
    if (pack.mode === "express") {
      codeColor = "#007BFF";
    } else if (pack.mode === "air") {
      codeColor = "#17A2B8";
    } else {
	 codeColor = "#7C341D";
	}
	
    return (
        <li
            className={`list-group-item position-relative`}
            style={{
                borderLeftWidth: "5px",
                borderLeftColor: pack.mode === "express" ? "rgba(0,123,255,.5)" : "",
                lineHeight: 1
            }}
            key={pack.id}
        >
            <div className="row position-static">
                <div className="col position-static" onClick={()=> onClick(pack.id)}>
                    <Link className="stretched-link" to={onClick ? '#' : `/packages/${pack.id}`}>
                        <code style={{color: codeColor}}>{pack.id}</code>
                    </Link>          					
					<div><small style={{color: codeColor}}>
					{modes[pack.mode]}					
					</small></div>					
                </div>
                <div className="col">
                    <small>
                        <span className="text-muted">Хүлээн авагчийн дугаар:</span>
                        <div dangerouslySetInnerHTML={{__html: phoneNumberStyler(pack.receiverPhone)}}></div>
                    </small>
                </div>
                    <div className="col">
                        <small>
                            <span className="text-muted">Хүлээн авагчийн нэр:</span>
                            <div>{pack.receiverName}</div>
                        </small>
                    </div>
                    <div className="col">
                        <small>
                            <span className="text-muted">Хүлээн авагчийн хаяг:</span>
                            <div>{pack.receiverAddress}</div>
                        </small>
                    </div>
            </div>
        </li>
    );
}

export default ReceiverListItem;