import React, { useState, useEffect } from 'react';

const ButtonPhoneInput = (props: any) => {
    const [id] = useState(Math.random().toString().split(".")[1]);
    const [code, setCode] = useState(props.defaultCode || "+976");
    const [number, setNumber] = useState("");
    const {onClick} = props

    useEffect(() => {

        setCode(props.code || props.defaultCode)
        setNumber(props.number)
    }, [props.code, props.defaultCode, props.number])

    
    useEffect(() => {
        if (props.state) {
            setCode(((props.state || "").match(/\+(976|82)/) || [])[0] || "+976");
            setNumber(((props.state || "").match(/\+(976|82)([0-9]+)/) || [])[2] || "");
        }
    }, [props.state])

    useEffect(() => {

        if (code) {
            props.setCode(code)
        }
        if (number) {
            props.setNumber(number)
        }

    }, [props, code, number])

    return (
        <div className="form-group">
            {props.label &&
                <label htmlFor={id}>{props.label}:</label>
            }
            <div className="input-group mb-3">
                <div className="input-group-prepend">
                    <button className="btn btn-secondary dropdown-toggle" type="button" data-toggle="dropdown">{code}</button>
                    <div className="dropdown-menu">
                        <div role="button" className="dropdown-item" onClick={() => setCode("+976")}>
                            <span className="badge badge-secondary">+976</span> Монгол
                        </div>
                        <div role="button" className="dropdown-item" onClick={() => setCode("+82")}>
                            <span className="badge badge-secondary">+82</span> Солонгос
                        </div>
                    </div>
                </div>
                <input type="number" value={number} className="form-control" id={id} defaultValue={number} onChange={(e) => setNumber(e.target.value)} />
                <button className="btn btn-primary float-right" onClick={onClick}>
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-search" viewBox="0 0 16 16">
                    <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001q.044.06.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1 1 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0"/>
                    </svg>&nbsp;
                </button>
                {props.append && (
                    <div className="input-group-append">
                        {typeof props.append === "string" ?
                            <div className="input-group-text">{typeof props.append}</div>
                        :
                            <>{props.append}</>
                        }
                    </div>
                )}
            </div>
        </div>
    );
}

export default ButtonPhoneInput;