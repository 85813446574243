import React, { useState, useEffect } from 'react';

const CurrencyInput = (props: any) => {
    const [id] = useState(Math.random().toString().split(".")[1]);
    const [code, setCode] = useState(props.defaultCode || "₩");
    const [price, setPrice] = useState("");

    useEffect(() => {
        if (code && price) {
            props.setState(price + code)
        }
    }, [props, code, price])
	
	

    return (
        <div className="form-group">
            {props.label &&
                <label htmlFor={id}>{props.label}:</label>
            }
            <div className="input-group mb-3">
                <div className="input-group-prepend">
                    <button className="btn btn-secondary dropdown-toggle" type="button" data-toggle="dropdown">{code}</button>
                    <div className="dropdown-menu">
					    <div role="button" className="dropdown-item" onClick={() => setCode("₩")}>
                            <span className="badge badge-secondary">₩</span> Вон
                        </div>
                        <div role="button" className="dropdown-item" onClick={() => setCode("₮")}>
                            <span className="badge badge-secondary">₮</span> Төгрөг
                        </div>
                        <div role="button" className="dropdown-item" onClick={() => setCode("$")}>
                            <span className="badge badge-secondary">$</span> Доллар
                        </div>						
                    </div>
                </div>
                <input className="form-control" id={id} defaultValue={props.state} onChange={(e) => setPrice(e.target.value)} />
                {props.append && (
                    <div className="input-group-append">
                        {typeof props.append === "string" ?
                            <div className="input-group-text">{typeof props.append}</div>
                        :
                            <>{props.append}</>
                        }
                    </div>
                )}
            </div>
        </div>
    );
}

export default CurrencyInput;