import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import Home from './components/Home';
import PackCreate from './components/Pack/Create';
import Pack from './components/Pack/View';
import Packs from './components/Pack/List';
import PackEdit from './components/Pack/Edit';
import Print from './components/Pack/Print';
import PrintEN from './components/Pack/PrintEN';
import Menu from './components/Menu';
import Auth from './components/Auth';
import PrivateRoute from './components/PrivateRoute';
import Track from './components/Track';
import { AuthProvider } from './components/AuthProvider';
import BatchUpdate from './components/Pack/BatchUpdate';
import BatchInsert from './components/Pack/BatchInsert';
import Footer from './components/Footer';
import List from './components/User/List';
/**
*GAALI - Bayarkhuu - 23/02/12
*/ 
import Gaalis from './components/Gaali/List';
import GaaliCreate from './components/Gaali/Create';

const App = () => {
  return (
    <AuthProvider>
      <Router>
        <Menu />
        <div className="container mb-5">
          <Switch>
            <Route exact path="/" component={Home} />
            <PrivateRoute exact path="/packages/create" component={PackCreate} />
            <PrivateRoute exact path="/packages" component={Packs} />
			<PrivateRoute exact path="/gaalis" component={Gaalis} />		
			<PrivateRoute exact path="/gaalis/create" component={GaaliCreate} />
            <PrivateRoute exact path="/packages/edit/:id" component={PackEdit} />
            <PrivateRoute exact path="/packages/batch_update" component={BatchUpdate} />
            <PrivateRoute exact path="/packages/batch_insert" component={BatchInsert} />
            <PrivateRoute exact path="/packages/:id" component={Pack} />
            <PrivateRoute exact path="/print/:id" component={Print} />		
	        <PrivateRoute exact path="/printen/:id" component={PrintEN} />				
            <PrivateRoute exact path="/users" component={List} />
            <Route path="/track/:id" component={Track} />
            <Route path="/auth" component={Auth} />
          </Switch>
        </div>
        <Footer />
      </Router>
    </AuthProvider>
  );
}

export default App;