import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';

function Home() {
    const [id, setId] = useState("");
    const history = useHistory();

    return (
        <>
            <div style={{
                position: "absolute",
                height: "100%",
                width: "100%",
                top: 0,
                left: 0,
                zIndex: -1
            }}>
                <div className="carousel carousel-fade slide h-100" data-ride="carousel">
                    <div className="carousel-inner h-100">
                        <div className="carousel-item active h-100" style={{backgroundImage: "url(bg0.jpg)", backgroundSize: "cover", backgroundPosition: "center"}}></div>
                        <div className="carousel-item h-100" style={{backgroundImage: "url(bg1.jpg)", backgroundSize: "cover", backgroundPosition: "center"}}></div>
                        <div className="carousel-item h-100" style={{backgroundImage: "url(bg2.jpg)", backgroundSize: "cover", backgroundPosition: "center"}}></div>
                    </div>
                </div>
            </div>
            <br/>
            <br/>
            <br/>
            <br/>
            <br/>
            <br/>
            <br/>
            <br/>
            <br/>
            <br/>
            <br />
            <h2 className="text-center d-md-block">Хурдан, хялбар хүргэлтийн үйлчилгээ</h2>

            <div className="input-group input-group-lg mt-md-5 shadow-lg" style={{borderRadius: 0}}>
                <input type="text" className="form-control" placeholder="Ачаа шалгах кодыг энд оруулна уу: Жишээ 220101-0001 (ИЛГЭЭСЭН ОНСАРӨДӨР-КОД)" value={id} onChange={(e) => setId(e.target.value)} style={{
                    borderRadius: 0,
                    border: "none",
                    backgroundColor: "rgba(255, 255, 255, .7)"
                }} />
                <div className="input-group-append">
                    <button className="btn" type="button" onClick={() => history.push(`/track/${id}`)} style={{borderRadius: 0, backgroundColor: "rgba(255, 255, 255, .7)"}}>
                        &nbsp;
                        &nbsp;
                        <svg width="1em" height="1em" viewBox="0 0 16 16" className="bi bi-box-seam" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                            <path fillRule="evenodd" d="M8.186 1.113a.5.5 0 0 0-.372 0L1.846 3.5l2.404.961L10.404 2l-2.218-.887zm3.564 1.426L5.596 5 8 5.961 14.154 3.5l-2.404-.961zm3.25 1.7l-6.5 2.6v7.922l6.5-2.6V4.24zM7.5 14.762V6.838L1 4.239v7.923l6.5 2.6zM7.443.184a1.5 1.5 0 0 1 1.114 0l7.129 2.852A.5.5 0 0 1 16 3.5v8.662a1 1 0 0 1-.629.928l-7.185 2.874a.5.5 0 0 1-.372 0L.63 13.09a1 1 0 0 1-.63-.928V3.5a.5.5 0 0 1 .314-.464L7.443.184z" />
                        </svg>
                        &nbsp;
                        &nbsp;
                    </button>
                </div>
            </div>
			<br />
            <h6 className="text-center d-md-block">Монгол хаяг: БЗД 25 хороо Нарны зам Миний хайпермаркетаас баруун зүгрүү 200м Болор авто угаалгын хашаан дотор <a href="https://goo.gl/maps/mtYSQ97zWgpe8VTm7">Google Map</a></h6>			
            <h6 className="text-center d-md-block">Ажиллах хуваарь: Даваа-Баасан: 10:00-19:00, Бямба: 10:00-15:00, Цайны цаг: 14:00-15:00, Утас: 8807-7555, 86267555, 7100-7555</h6>
			
            {/* <div className="row justify-content-center">
                <div className="col-12 col-md-8 col-lg-6">
                    <img src="bnnr.jpeg" className="img-fluid" alt="ad" />
                </div>
            </div> */}
        </>
    );
}

export default Home;