import React, { useState, useEffect, useContext } from 'react';
import firebase from "../firebase";
import { AuthContext } from "./AuthProvider";
import { useHistory } from 'react-router-dom';
import PhoneInput from "./elements/PhoneInput";

declare global {
  interface Window {
    recaptchaVerifier: any;
    confirmationResult: any;
  }
}

function Auth() {
  const [code, setCode] = useState<string>("");
  const [phone, setPhone] = useState<string>("");
  const [sent, setSent] = useState(false);
  const { currentUser } = useContext(AuthContext);
  const history = useHistory();

  useEffect(() => {
    window.recaptchaVerifier = new firebase.auth.RecaptchaVerifier('sign-in-button', {
      'size': 'invisible',
    });
  }, []);

  useEffect(() => {
    if (currentUser) {
      history.push("/");
    }
  }, [currentUser, history]);

  const send = () => {
    var appVerifier = window.recaptchaVerifier;
    firebase.auth().signInWithPhoneNumber(phone, appVerifier).then((confirmationResult) => {
      window.confirmationResult = confirmationResult;
      setSent(true);
    }).catch(console.error);
  }

  const login = () => {
    window.confirmationResult.confirm(code).catch(console.error);
  }

  return (
    <div className="row justify-content-md-center">
      <div className="col-md-auto">
        {!sent ?
          <div className="input-group mb-3">
            <PhoneInput state={phone} setState={setPhone} defaultCode="+82" append={
              <button className="btn btn-success" onClick={send}>
                <svg width="1em" height="1em" viewBox="0 0 16 16" className="bi bi-chat-left-dots" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                  <path fillRule="evenodd" d="M14 1H2a1 1 0 0 0-1 1v11.586l2-2A2 2 0 0 1 4.414 11H14a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1zM2 0a2 2 0 0 0-2 2v12.793a.5.5 0 0 0 .854.353l2.853-2.853A1 1 0 0 1 4.414 12H14a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2z" />
                  <path d="M5 6a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm4 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm4 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0z" />
                </svg>&nbsp;
                Код явуулах
              </button>
            } />
          </div>
        :
          <div className="input-group mb-3">
            <input type="phone" className="form-control" value={code} onChange={(e) => setCode(e.target.value)} placeholder="Код" />
            <div className="input-group-append">
              <button className="btn btn-primary" onClick={login}>
                <svg width="1em" height="1em" viewBox="0 0 16 16" className="bi bi-door-open" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                  <path fillRule="evenodd" d="M1 15.5a.5.5 0 0 1 .5-.5h13a.5.5 0 0 1 0 1h-13a.5.5 0 0 1-.5-.5zM11.5 2H11V1h.5A1.5 1.5 0 0 1 13 2.5V15h-1V2.5a.5.5 0 0 0-.5-.5z" />
                  <path fillRule="evenodd" d="M10.828.122A.5.5 0 0 1 11 .5V15h-1V1.077l-6 .857V15H3V1.5a.5.5 0 0 1 .43-.495l7-1a.5.5 0 0 1 .398.117z" />
                  <path d="M8 9c0 .552.224 1 .5 1s.5-.448.5-1-.224-1-.5-1-.5.448-.5 1z" />
                </svg>&nbsp;
                Нэвтрэх
              </button>
            </div>
          </div>
        }
      </div>
    </div>
  );
}

export default Auth;