import React, { useContext } from "react";
import { Route, Redirect } from "react-router-dom";
import { AuthContext } from "./AuthProvider";

const PrivateRoute = (props: any) => {
    const { component: Component, ...rest } = props;
    const { currentUser } = useContext(AuthContext);

    return (
        <Route
            {...rest}
            render={(routeProps) => (
                currentUser || currentUser === undefined ? (
                    <Component {...routeProps} />
                ) : (
                    <Redirect to="/auth" />
                )
            )}
        />
    );
};

export default PrivateRoute;