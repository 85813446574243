import React, { useState } from 'react';

const Select = (props: any) => {
    const [id] = useState(Math.random().toString().split(".")[1]);
	
type HtmlEvent = React.ChangeEvent<HTMLSelectElement>

const onChange: React.EventHandler<HtmlEvent> = 
   (e: HtmlEvent) => { 		
	   props.setState(e.target.value)
   }
	
    return (
        <div className="form-group" style={props.style}>
            <label htmlFor={id}>{props.label}</label>
            <select id={id} className="custom-select" value={props.state} onChange={ e => onChange(e) }>
                {Object.keys(props.options).map((key) => (
                    <option key={key} value={key}>
                        {props.options[key]}
                    </option>
                ))}
            </select>		
        </div>
		
    );
	
}

export default Select;