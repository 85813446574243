import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import firebase from "../../firebase";
import { useBarcode } from 'react-barcodes';
import { modes, paymentMethods, deliveryOptions, paymentUtilities } from "../../enums";
import { phoneNumberStyler } from "../../helpers";

const rotateStyle = {
	marginLeft:"0px",
	marginTop: "350px",
    transform: "rotate(-90deg)",
	width:"120%",
	border: "hidden",
	background: "white",
	boxShadow: "none"
};


const db = firebase.firestore();

function Print() {
    const {id} = useParams<{id: string}>();
    const [trackId] = useState<string>(id);		
	const { inputRef } = useBarcode({
    value: trackId,
    options: {
	  height : 250,
	  width: 4,
    }
	});
    const [quantity, setQuantity] = useState("");
    const [senderPhone, setSenderPhone] = useState("");
    const [senderName, setSenderName] = useState("");	
    const [receiverPhone, setReceiverPhone] = useState("");
    const [receiverName, setReceiverName] = useState("");	
    const [receiverAddress, setReceiverAddress] = useState("");		
    const [weight, setWeight] = useState();
    const [mode, setMode] = useState("");
    const [price, setPrice] = useState("");	
	const [paymentUtility, setPaymentUtility] = useState("");
	const [paymentMethod, setPaymentMethod] = useState("");
    const [hasDelivery, setHasDelivery] = useState("");
	const [orderCode, setOrderCode] = useState("");
    const [docRef] = useState<firebase.firestore.DocumentReference>(db.collection("packages").doc(id));
	
	let bankAccount;
    if (mode == "express") {
      bankAccount = "Экспресс ачаа -  Sukhbaatar Khishigjargal, Hana Bank, 11991054268407";
    } else if (mode == "air") {
      bankAccount = "Агаар ачаа - Sukhbaatar Khishigjargal, Kookmin, 29250201176233";
    } else {
      bankAccount = "Энгийн ачаа - Sukhbaatar Khishigjargal, Woori Bank, 1002058431790";
	}

/**	
    useEffect(() => {
        firebase.functions().httpsCallable('getPackage')({id}).then((data) => {
            if (data.data) {
                setSenderPhone(data.data?.senderPhone);
                setSenderName(data.data?.senderName);						
                setReceiverPhone(data.data?.receiverPhone);
                setReceiverName(data.data?.receiverName);	
				setReceiverAddress(data.data?.receiverAddress);	
                setWeight(data.data?.weight);
				setQuantity(data.data?.quantity);
                setMode(data.data?.mode || Object.keys(modes)[0]);
				setPaymentUtility(data?.paymentUtility);
				setPaymentMethod(data?.paymentMethod);
                setHasDelivery(data?.hasDelivery);
            }
        }).catch(console.error);
    }, [id]);
**/

    useEffect(() => {
        docRef.get().then((doc) => {
            if (doc.exists) {
                let data = doc.data();
                setSenderName(data?.senderName);
                setSenderPhone(data?.senderPhone);
                setReceiverName(data?.receiverName);
                setReceiverPhone(data?.receiverPhone);
                setReceiverAddress(data?.receiverAddress);				
                setWeight(data?.weight);
				setPrice(data?.price);
				setOrderCode(data?.orderCode);
                setPaymentMethod(data?.paymentMethod);
                setHasDelivery(data?.hasDelivery);
                setMode(data?.mode || Object.keys(modes)[0]);
                setQuantity(data?.quantity);
                setPaymentUtility(data?.paymentUtility);
            }
        }).catch(console.error);
    }, [id, docRef]);

    return (
        <>
			<div >
                    <table className="table" style={rotateStyle}>
							<tr>
								<td align="left"><svg ref={inputRef} /> <br/></td>
								<td align="left"><h2>Ачааны код:</h2><h1><strong>{trackId}</strong></h1>
								<h2>Ангилал: <strong>{modes[mode]}</strong>, Дэс дугаар: {orderCode}</h2>
								<h2>Барааны жин: <strong>{weight}кг</strong></h2>
								<h2>Ачааны тоо ширхэг: <strong>{quantity}ш</strong></h2>
								<h2>Үнийн дүн: {price}</h2>
								Данс: <h3><i><strong>{bankAccount}</strong></i></h3>
								</td>
							</tr>
						    <tr>
								<td align="left">Илгээгчийн нэр: <h1><strong>{senderName}</strong></h1></td>
								<td align="left">Илгээгчийн дугаар: <h1><strong>{senderPhone.slice(0, -3) + "***"}</strong></h1></td>
							</tr>
							<tr>
								<td align="left">Хүлээн авагч: <h1><strong>{receiverName}</strong></h1></td>								
								<td align="left">Хүлээн авагчийн дугаар: <h1><strong>{receiverPhone.slice(0, -3) + "***"}</strong></h1></td>
							</tr>
							<tr>
								<td align="left">Хүлээн авагчийн хаяг: <h2><strong>{receiverAddress}</strong></h2></td>															
							</tr>
                    </table>					
				</div>
        </>
    );
}

export default Print;