import React, { useEffect, useState } from 'react';
import firebase from "../../firebase";
import { roles } from "../../enums";
import ListItem from './ListItem';
import PhoneInput from '../elements/PhoneInput';
import Select from '../elements/Select';

const db = firebase.firestore();

function List() {
    const [users, setUsers] = useState<any[]>([]);
    const [phoneNumber, setPhoneNumber] = useState("");
    const [role, setRole] = useState(Object.keys(roles)[0]);

    const get = () => {
        db.collection("users").get().then((snapshot: any) => {
            setUsers(snapshot.docs);
        });
    }

    useEffect(get, []);

    const add = () => {
        firebase.functions().httpsCallable('createUser')({ phoneNumber }).then((data: any) => {
            if (data.data?.uid) {
                db.doc(`users/${data.data.uid}`).set({ role, phoneNumber }).then(get).catch(console.error);
            }
        });
    }

    return (
        <>
            <ul className="list-group mb-2">
                {users.map((pack) => <ListItem pack={pack} key={pack.id} get={get} /> )}
            </ul>
            <div className="row mt-4">
                <div className="col">
                    <PhoneInput state={phoneNumber} setState={setPhoneNumber} defaultCode="+82" />
                </div>
                <div className="col">
                    <Select state={role} setState={setRole} options={roles} />
                </div>
                <div className="col">
                    <button className="btn btn-primary btn-block" onClick={add}>
                        <svg width="1em" height="1em" viewBox="0 0 16 16" className="bi bi-plus-square" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                            <path fillRule="evenodd" d="M14 1H2a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1zM2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2z" />
                            <path fillRule="evenodd" d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z" />
                        </svg>&nbsp;
                        Ажилтан нэмэх
                    </button>
                </div>
            </div>
            <br />
        </>
    );
}

export default List; 