import React, { useContext, useEffect, useState } from 'react';
import { useHistory, Link } from 'react-router-dom';
import firebase from "../../firebase";
import Input from "../elements/Input";
import Select from "../elements/Select";
import PhoneInput from "../elements/PhoneInput";
import { AuthContext } from '../AuthProvider';
import Textarea from '../elements/Textarea';

const db = firebase.firestore();

const Create = () => {
    const {currentUser} = useContext(AuthContext);
	const [houseSEQ, setHouseSEQ] = useState("");
	const [mailID, setMailID] = useState("");
	const [mailBagNumber, setMailBagNumber] = useState("");
	const [blNO, setBlNO] = useState("");
	const [reportType, setReportType] = useState("");
	const [riskType, setRiskType] = useState("");
	const [transFare, setTransFare] = useState("");
	const [goodsNM, setGoodsNM] = useState("");
	const [netWGT, setNetWGT] = useState("");
	const [wgt, setWgt] = useState("");
	const [wgtUnit, setWgtUnit] = useState("");
	const [qty, setQty] = useState("");
	const [qtyUnit, setQtyUnit] = useState("");
	const [dangGoodsCode, setDangGoodsCode] = useState("");
	const [hsCode, setHsCode] = useState("");
	const [isDiplomat, setIsDiplomat] = useState("");
	const [price1, setPrice1] = useState("");
	const [priceCurr1, setPriceCurr1] = useState("");
	const [price2, setPrice2] = useState("");
	const [priceCurr2, setPriceCurr2] = useState("");
	const [price3, setPrice3] = useState("");
	const [priceCurr3, setPriceCurr3] = useState("");
	const [price4, setPrice4] = useState("");
	const [priceCurr4, setPriceCurr4] = useState("");
	const [price5, setPrice5] = useState("");
	const [priceCurr5, setPriceCurr5] = useState("");
	const [shipperTel, setShipperTel] = useState("");
	const [shipperNM, setShipperNM] = useState("");
	const [shipperCntryCD, setShipperCntryCD] = useState("");
	const [shipperCntryNM, setShipperCntryNM] = useState("");
	const [shipperNatinality, setShipperNatinality] = useState("");
	const [shipperAddr, setShipperAddr] = useState("");
	const [shipperReg, setShipperReg] = useState("");
	const [consigneeTel, setConsigneeTel] = useState("");
	const [consigneeNM, setConsigneeNM] = useState("");
	const [consigneeCntryCD, setConsigneeCntryCD] = useState("");
	const [consigneeCntryNM, setConsigneeCntryNM] = useState("");
	const [consigneeNatinality, setConsigneeNatinality] = useState("");
	const [consigneeAddr, setConsigneeAddr] = useState("");
	const [consigneeReg, setConsigneeReg] = useState("");
	const [compTel, setCompTel] = useState("");
	const [compName, setCompName] = useState("");
	const [compAddr, setCompAddr] = useState("");
	const [compRegister, setCompRegister] = useState("");
	const [ecommerceType, setEcommerceType] = useState("");
	const [ecommerceLink, setEcommerceLink] = useState("");

	const history = useHistory();



    const create = () => {
        const id = (new Date()).toJSON().split("T")[0].replace(/-/g, "").substring(2)+"-"+ Math.random().toString().substr(2, 4);
		
		 db.doc(`gaalis/${id}`).set({
			 houseSEQ,
			 mailID,
			 mailBagNumber,
			 blNO,
			 reportType,
			 riskType, 
			 transFare,
			 goodsNM, 
			 netWGT, 
			 wgt,
			 wgtUnit, 
			 qty, 
			 qtyUnit,
			 dangGoodsCode, 
			 hsCode, 
			 isDiplomat, 
			 price1, 
			 priceCurr1, 
			 price2, 
			 priceCurr2, 
			 price3, 
			 priceCurr3,
			 price4, 
			 priceCurr4, 
			 price5, 
			 priceCurr5, 
			 shipperTel, 
			 shipperNM, 
			 shipperCntryCD,
			 shipperCntryNM,
			 shipperNatinality,
			 shipperAddr, 
			 shipperReg, 
			 consigneeTel, 
			 consigneeNM, 
			 consigneeCntryCD, 
			 consigneeCntryNM, 
			 consigneeNatinality,
			 consigneeAddr, 
			 consigneeReg, 
			 compTel, 
			 compName,
			 compAddr, 
			 compRegister, 
			 ecommerceType, 
			 ecommerceLink, 	
        }).then(() => {
            history.push(`/gaalis/${id}`);
        }).catch(console.error);
		
		
    }

    return (
        <>
			<div className="row position-static">
			    <div className="col position-static">
				   <Input state={houseSEQ} setState={setHouseSEQ} label="ID" />
				</div>
                <div className="col position-static">
				   <Input state={mailID} setState={setMailID} label="Илгээмжийн №" />
				</div>
				<div className="col">
                   <Input state={mailBagNumber} setState={setMailBagNumber} label="Богцын №" />
				</div>
				<div className="col">
                   <Input state={blNO} setState={setBlNO} label="Тээврийн баримт №" />
				</div>
				<div className="col">
                   <Input state={reportType} setState={setReportType} label="Маягтын төрөл" />
				</div>
				<div className="col">
                  <Input state={goodsNM} setState={setGoodsNM} label="Ачааны нэр" />
				</div>
			</div>
			<div className="row position-static">			
				<div className="col">
                  <Input state={riskType} setState={setRiskType} label="Эрсдлийн төлөв/үнэлгээ" />
				</div>
				<div className="col">
                  <Input state={transFare} setState={setTransFare} label="Тээврийн зардал үнэ" />
				</div>
				<div className="col">
                  <Input state={netWGT} setState={setNetWGT} label="Цэвэр жин" />
				</div>
				<div className="col">
                  <Input state={wgt} setState={setWgt} label="Бохир жин" />
				</div>				
				<div className="col">
                  <Input state={wgtUnit} setState={setWgtUnit} label="Х/нэгжийн код" />
				</div>

			</div>
			<div className="row position-static">			
				<div className="col">
                  <Input state={qty} setState={setQty} label="Баглаа боодлын тоо" />
				</div>
				<div className="col">
                  <Input state={qtyUnit} setState={setQtyUnit} label="Баглаа боодлын нэгж" />
				</div>				
				<div className="col">
                  <Input state={dangGoodsCode} setState={setDangGoodsCode} label="Аюултай барааны код" />
				</div>				
				<div className="col">
                  <Input state={hsCode} setState={setHsCode} label="БТКУС код" />
				</div>				
				<div className="col">
                  <Input state={isDiplomat} setState={setIsDiplomat} label="Дипломат эсэх" />
				</div>								
			</div>

			<div className="row position-static">			
				<div className="col">
                  <Input state={price1} setState={setPrice1} label="Барааны үнэ 1" />				
				</div>
				<div className="col">
                  <Input state={priceCurr1} setState={setPriceCurr1} label="Валютын код 1" />				
				</div>				
				<div className="col">
                  <Input state={price2} setState={setPrice2} label="Барааны үнэ 2" />				
				</div>
				<div className="col">
                  <Input state={priceCurr2} setState={setPriceCurr2} label="Валютын код 2" />				
				</div>
				<div className="col">
                  <Input state={price3} setState={setPrice3} label="Барааны үнэ 3" />				
				</div>
				<div className="col">
                  <Input state={priceCurr3} setState={setPriceCurr3} label="Валютын код 3" />				
				</div>
				<div className="col">
                  <Input state={price4} setState={setPrice4} label="Барааны үнэ 4" />				
				</div>
				<div className="col">
                  <Input state={priceCurr4} setState={setPriceCurr4} label="Валютын код 4" />				
				</div>
				<div className="col">
                  <Input state={price5} setState={setPrice5} label="Барааны үнэ 5" />				
				</div>
				<div className="col">
                  <Input state={priceCurr5} setState={setPriceCurr5} label="Валютын код 5" />				
				</div>
			</div>
			<div className="row position-static">			
				<div className="col">
                  <Input state={shipperTel} setState={setShipperTel} label="Илгээгчийн дугаар" />
				</div>
				<div className="col">
                  <Input state={shipperNM} setState={setShipperNM} label="Илгээгчийн нэр" />
				</div>
				<div className="col">
                  <Input state={shipperCntryCD} setState={setShipperCntryCD} label="Улсын код" />
				</div>
				<div className="col">
                  <Input state={shipperCntryNM} setState={setShipperCntryNM} label="Улсын нэр" />
				</div>
				<div className="col">
                  <Input state={shipperNatinality} setState={setShipperNatinality} label="Харяалал" />
				</div>
				<div className="col">
                  <Input state={shipperReg} setState={setShipperReg} label="Регистр" />
				</div>				

			</div>
			<div className="row position-static">						
				<div className="col">
                  <Input state={shipperAddr} setState={setShipperAddr} label="Илгээгчийн хаяг" />
				</div>
			</div>
			<div className="row position-static">						
				<div className="col">
                  <Input state={consigneeTel} setState={setConsigneeTel} label="Хүлээн авагчын дугаар" />
				</div>
				<div className="col">
                  <Input state={consigneeNM} setState={setConsigneeNM} label="Нэр" />
				</div>
				<div className="col">
                  <Input state={consigneeCntryCD} setState={setConsigneeCntryCD} label="Улсын код" />
				</div>
				<div className="col">
                  <Input state={consigneeCntryNM} setState={setConsigneeCntryNM} label="Улсын нэр" />
				</div>
				<div className="col">
                  <Input state={consigneeNatinality} setState={setConsigneeNatinality} label="Харяалал" />
				</div>
			</div>
			<div className="row position-static">						
				<div className="col">
                  <Input state={consigneeAddr} setState={setConsigneeAddr} label="Хүлээн авагчын хаяг" />
				</div>
				<div className="col">
                  <Input state={consigneeReg} setState={setConsigneeReg} label="Регистр" />
				</div>
			</div>
			
			<div className="row position-static">						
				<div className="col">
                  <Input state={compTel} setState={setCompTel} label="Зуучлагч байгууллагын дугаар" />
				</div>
				<div className="col">
                  <Input state={compName} setState={setCompName} label="Зуучлагч байгууллагын нэр" />
				</div>
				<div className="col">
                  <Input state={compAddr} setState={setCompAddr} label="Хаяг" />
				</div>
				<div className="col">
                  <Input state={compRegister} setState={setCompRegister} label="Регистр" />
				</div>				
			</div>
			<div className="row position-static">						
				<div className="col">
                  <Input label="Илгээмж бүртгэгдсэн огноо" />
				</div>
				<div className="col">
                  <Input label="Илгээгч улсаас ачаа хөдөлсөн огноо" />
				</div>
			</div>
			<div className="row position-static">										
				<div className="col">
                  <Input state={ecommerceType} setState={setEcommerceType} label="Цахим худалдааны төлбөрийн баримтын хуулбар" />
				</div>
				<div className="col">
                  <Input state={ecommerceLink} setState={setEcommerceLink} label="Цахим худалдааны линк" />
				</div>				
			</div>

			
			<button className="btn btn-primary float-right" onClick={create}>
                <svg width="1em" height="1em" viewBox="0 0 16 16" className="bi bi-plus-square" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                    <path fillRule="evenodd" d="M14 1H2a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1zM2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2z" />
                    <path fillRule="evenodd" d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z" />
                </svg>&nbsp;
                Үүсгэх
            </button>
        </>
    );
}

export default Create;