import React, { useContext, useEffect, useState } from 'react';
import firebase from "../../firebase";
import { roles } from "../../enums";
import { Link } from 'react-router-dom';
import ListItem from './ListItem';
import PhoneInput from '../elements/PhoneInput';
import Select from '../elements/Select';
import { AuthContext } from '../AuthProvider';

const db = firebase.firestore();

function List() {
    const [gaalis, setGaalis] = useState<any[]>([]);
    const { currentUser } = useContext(AuthContext);

    const get = () => {
        db.collection("gaalis").get().then((snapshot: any) => {
			setGaalis(snapshot.docs.map((pack: { id: any; data: () => any; }) => ({ id: pack.id, ...pack.data() })));
        });
    }

    useEffect(get, []);

    return (
        <>
		    {["worker", "admin"].indexOf(currentUser?.role) !== -1 && <>
                <Link className="btn btn-primary float-right" to="/gaalis/create">
                    <svg width="1em" height="1em" viewBox="0 0 16 16" className="bi bi-plus-square" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                        <path fillRule="evenodd" d="M14 1H2a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1zM2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2z" />
                        <path fillRule="evenodd" d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z" />
                    </svg>&nbsp;
                    Гаалийн шуудан нэмэх
                </Link>
            </>}
            <br />
			&nbsp;
            <ul className="list-group mb-2">
                {gaalis.map((gaali) => <ListItem gaali={gaali} key={gaali.id} get={get} /> )}
            </ul>
            <br />
        </>
    );
}

export default List; 