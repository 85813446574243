import React, { useState } from 'react';

const SelectRemovePaymentMethod = (props: any) => {
    const [id] = useState(Math.random().toString().split(".")[1]);

    return (
        <div className="form-group" style={props.style}>
            <label htmlFor={id}>{props.label}</label>
            <select id={id} className="custom-select" value={props.state} onChange={(e) => props.setState(e.target.value)}>
                {Object.keys(props.options).map((key) => (
                    <option key={key} value={key} disabled={key == "paid"}>
                        {props.options[key]}
                    </option>
                ))}
            </select>
        </div>
    );
}

export default SelectRemovePaymentMethod;