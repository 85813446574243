import React, { useContext, useState } from 'react';
import { AuthContext } from '../AuthProvider';

const SelectEditWorker = (props: any) => {
    const {currentUser} = useContext(AuthContext);

    const [id] = useState(Math.random().toString().split(".")[1]);
		
    return (
        <div className="form-group" style={props.style}>
            <label htmlFor={id}>{props.label}</label>
			
            <select disabled={["worker"].indexOf(currentUser?.role)==0} id={id} className="custom-select" value={props.state} onChange={(e) => props.setState(e.target.value)} >
                {Object.keys(props.options).map((key) => (
                    <option key={key} value={key}>
                        {props.options[key]}
                    </option>
                ))}
            </select>
        </div>
    );
}

export default SelectEditWorker;