import React, { useState, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import firebase from "../firebase";
import { stats, modes, paymentMethods, deliveryOptions } from "../enums";
import { phoneNumberStyler } from '../helpers';

function Track() {
    const {id} = useParams<{id: string}>();
    const [trackId] = useState<string>(id);
    const [status, setStatus] = useState<string>("");
    const [senderPhone, setSenderPhone] = useState("");
    const [receiverPhone, setReceiverPhone] = useState("");
    const [quantity, setQuantity] = useState("");	
    const [price, setPrice] = useState("");		
    const [weight, setWeight] = useState();
    const [paymentMethod, setPaymentMethod] = useState("");
    const [hasDelivery, setHasDelivery] = useState("");
    const [arrivalDate, setArrivalDate] = useState("");
    const [mode, setMode] = useState("");
    const [desc, setDesc] = useState("");
    const [value, setValue] = useState("");
    const [postfee, setPostfee] = useState("");
	const [tegbeCode, setTegbeCode] = useState("");
    const [orderCode, setOrderCode] = useState("");	

    const [loading, setLoading] = useState(true);

    useEffect(() => {
        firebase.functions().httpsCallable('getPackage')({id}).then((data) => {
            if (data.data) {
                setSenderPhone(data.data?.senderPhone);
                setReceiverPhone(data.data?.receiverPhone);
				setQuantity(data.data?.quantity);
				setPrice(data.data?.price);				
                setWeight(data.data?.weight);
                setPaymentMethod(data.data?.paymentMethod);
                setHasDelivery(data.data?.hasDelivery);
                setMode(data.data?.mode || Object.keys(modes)[0]);
                setStatus(data.data?.status || Object.keys(stats)[0]);
				
                let week = 7*24*60*60*1000;
                let duration = data.data?.mode === "express" ? week : week*2;
                let departAt = new Date(data.data?.departTime);
                let arrive = new Date(departAt.getTime() + duration);
                setArrivalDate(arrive.toJSON().substr(0, 10));				
				
                setDesc(data.data?.desc);
                setValue(data.data?.value);
				setPostfee(data.data?.postfee);
				setTegbeCode(data.data?.tegbeCode);
				setOrderCode(data.data?.orderCode);
            }
            setLoading(false);
        }).catch(console.error);
    }, [id]);

    return (
        <>
            <small>Захиалгын дугаар:</small>
            <h3>{trackId}</h3>

            {loading ? <>
                <div className="text-center">
                    <div className="spinner-border text-primary" role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                </div>
            </> : <>
                {status ? <>
                    <table className="table table-bordered">
                        <tbody>
                            <tr>
                                <td className="table-active">Төлөв</td>
                                <td>{stats[status]}</td>
                            </tr>
                            <tr>
                                <td className="table-active">Илгээгчийн утас</td>
                                <td dangerouslySetInnerHTML={{__html: phoneNumberStyler(senderPhone)}}></td>
                            </tr>
                            <tr>
                                <td className="table-active">Хүлээн авагчийн утас</td>
                                <td dangerouslySetInnerHTML={{__html: phoneNumberStyler(receiverPhone)}}></td>
                            </tr>
                            <tr>
                                <td className="table-active">Жин</td>
                                <td>{weight}<span className="text-muted">кг</span></td>
                            </tr>
                            <tr>
                                <td className="table-active">Төлбөрийн мэдээлэл</td>
                                <td>{paymentMethods[paymentMethod]}</td>
                            </tr>
                            <tr>
                                <td className="table-active">Монголд хүргэлттэй эсэх</td>
                                <td>{deliveryOptions[hasDelivery]}</td>
                            </tr>
                            <tr>
                                <td className="table-active">Ангилал</td>
                                <td>{modes[mode]}</td>
                            </tr>
                            <tr>
                                <td className="table-active">Ачааны мэдээлэл:</td>
                                <td>{desc}</td>
                            </tr>
							<tr>
                                <td className="table-active">Ачааны тоо ширхэг:</td>
                                <td>{quantity}</td>
                            </tr>
                            <tr>
                                <td className="table-active">Тэгбэ код:</td>
                                <td>{tegbeCode}</td>
                            </tr>
							<tr>
                                <td className="table-active">Дэс дарааллын код:</td>
                                <td>{orderCode}</td>
                            </tr>							
							<tr>
                                <td className="table-active">Үнийн дүн:</td>
                                <td>{price}</td>
                            </tr>				
							<tr>
                                <td className="table-active">Хүргэлтийн төлбөр:</td>
                                <td>{postfee}</td>
                            </tr>				

							<tr>
                                <td className="table-active">Экспресс ачаа:</td>
                                <td>Sukhbaatar Khishigjargal, Hana Bank, 11991054268407</td>
                            </tr>				
							<tr>
                                <td className="table-active">Агаар ачаа:</td>
                                <td>Sukhbaatar Khishigjargal, Kookmin, 29250201176233</td>
                            </tr>				
							<tr>
                                <td className="table-active">Энгийн ачаа:</td>
                                <td>Sukhbaatar Khishigjargal, Woori Bank, 1002058431790</td>
                            </tr>							
                        </tbody>
                    </table>
                </> : <>
                    <div className="alert alert-danger" role="alert">
                        ТАНЫ АЧААНЫ КОД БУРУУ БАЙНА ТА АЧААНЫ КОДОО ДАХИН ШАЛГАНА УУ
                    </div>
                </>}
            </>}
			<div>
			<strong>
			Анхааруулга: 
			<div></div>
			Таны ачаа гарах хуваарьт өдрөө Солонгос / Монгол  улсаас хөдлөх бөгөөд ачаа Солонгос / Монгол улсаас гарсан өдрөөсөө хугацаа тоологдох болохыг анхаарна уу!
			</strong>
			</div>
			<div>
			<strong>				
			Хувь хүн өөрөө баглаа боодлоо гүйцэтгэдэг учир ачаа эвдрэл болон хөлдөх тохиолдолд манай байгууллага хариуцлага хүлээхгүйг анхаарна уу!  
			</strong>
			</div>
			<div>
			<strong>				
			Амархан хагарч эвдрэх болон хориотой илгээмж явуулахгүй байхыг зөвлөж байна. (Ажилчдаас нуун дарагдуулж илгээсэн тохиолдолд хувь хүн гаалийн татвар болон торгууль мөн бусад хариуцлагыг өөрөө хүлээхийг анхаарна уу!)
			</strong>
			</div>
			<div>
			<strong>
			
			Илгээмжийг хэт удаан хугацаанд эзэмшигч хүлээж авахгүй байх тохиолдолд хадгалалтын хөлс нэмэгдэхийг анхаарна уу!
			</strong>
			</div>
			<div>
			&nbsp;
			</div><strong>
			Бидэнтэй холбогдох:</strong>
			<div>
			</div>
	    <li className="list-inline-item">
		 <a href="mailto:khishigtrade@gmail.com">И-мэйл:&nbsp; 			
			<svg width="24" height="24" viewBox="0 0 24 24"><path d="M0 3v18h24v-18h-24zm6.623 7.929l-4.623 5.712v-9.458l4.623 3.746zm-4.141-5.929h19.035l-9.517 7.713-9.518-7.713zm5.694 7.188l3.824 3.099 3.83-3.104 5.612 6.817h-18.779l5.513-6.812zm9.208-1.264l4.616-3.741v9.348l-4.616-5.607z"/>
		</svg>
		    </a>
        </li>	
<div>
</div>		
						
			      <ul className="list-inline">
	    <li className="list-inline-item">
		 <a href="https://maps.app.goo.gl/VZkmKEQQ4dUijNDJ6">Монгол дах хаяг:&nbsp;
		<svg style={{width: "48px", height: "24px"}} viewBox="0 0 24 24">
			<path d="M-1.547 12l6.563-6.609-1.406-1.406-5.156 5.203-2.063-2.109-1.406 1.406zM0 0q2.906 0 4.945 2.039t2.039 4.945q0 1.453-0.727 3.328t-1.758 3.516-2.039 3.070-1.711 2.273l-0.75 0.797q-0.281-0.328-0.75-0.867t-1.688-2.156-2.133-3.141-1.664-3.445-0.75-3.375q0-2.906 2.039-4.945t4.945-2.039z" />
		</svg>
		    </a>
        </li>	
<div>
</div>		
        <li className="list-inline-item">
          <a href="https://www.facebook.com/khishigcargo.mongoltown">Facebook:&nbsp;
            <svg style={{ width: "24px", height: "24px" }} viewBox="0 0 24 24">
              <path fill="black" d="M12 2.04C6.5 2.04 2 6.53 2 12.06C2 17.06 5.66 21.21 10.44 21.96V14.96H7.9V12.06H10.44V9.85C10.44 7.34 11.93 5.96 14.22 5.96C15.31 5.96 16.45 6.15 16.45 6.15V8.62H15.19C13.95 8.62 13.56 9.39 13.56 10.18V12.06H16.34L15.89 14.96H13.56V21.96A10 10 0 0 0 22 12.06C22 6.53 17.5 2.04 12 2.04Z" />
            </svg>
          </a>
        </li>
<div>
</div>			
        <li className="list-inline-item">
          <a href="tel:+97671007555">Холбогдох утас:&nbsp;
            <svg style={{width: "24px", height: "24px"}} viewBox="0 0 24 24">
              <path fill="black" d="M6.62,10.79C8.06,13.62 10.38,15.94 13.21,17.38L15.41,15.18C15.69,14.9 16.08,14.82 16.43,14.93C17.55,15.3 18.75,15.5 20,15.5A1,1 0 0,1 21,16.5V20A1,1 0 0,1 20,21A17,17 0 0,1 3,4A1,1 0 0,1 4,3H7.5A1,1 0 0,1 8.5,4C8.5,5.25 8.7,6.45 9.07,7.57C9.18,7.92 9.1,8.31 8.82,8.59L6.62,10.79Z" />
            </svg>
          </a>
        </li>
      </ul>


            <small className="d-block text-right">
                <strong>Солонгост холбогдох дугаар:</strong> 010-9802-7555<br />
                <strong>Монголд холбогдох дугаар:</strong> 8807-7555, 7100-7555
            </small>

            <Link className="btn btn-light" to="/">
                <svg width="1em" height="1em" viewBox="0 0 16 16" className="bi bi-caret-left" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                    <path fillRule="evenodd" d="M10 12.796L4.519 8 10 3.204v9.592zm-.659.753l-5.48-4.796a1 1 0 0 1 0-1.506l5.48-4.796A1 1 0 0 1 11 3.204v9.592a1 1 0 0 1-1.659.753z" />
                </svg>&nbsp;
                Буцах
            </Link>
		
        </>
    );
}

export default Track;