import React, {  } from 'react';
import { Link } from 'react-router-dom';
import { roles } from "../../enums";

function ListItem(props: any) {
    const { gaali } = props;	
	
    return (
        <li 
			className={`list-group-item position-relative`}
            style={{
                borderLeftWidth: "5px",
                borderLeftColor: gaali.transporType === "40" ? "rgba(0,123,255,.5)" : "",
                lineHeight: 1
            }}
            key={gaali.id}
		>
			<div className="row position-static">
                <div className="col position-static">
                    <Link className="stretched-link" to={`/gaalis/${gaali.id}`}>
                        <code>Дугаар: {gaali.houseSEQ}</code>
                    </Link>
                   <div><small>Илгээмжийн дугаар: {gaali.mailID}</small></div>
                   <div><small>Богцын дугаар: {gaali.mailBagNumber}</small></div>
	               <div><small>Тээврийн баримтын дугаар: {gaali.blNO}</small></div>		
	               <div><small>Маягтын төрөл: {gaali.reportType}</small></div>		
	               <div><small>Эрсдлийн төлөв/үнэлгээ: {gaali.riskType}</small></div>						   
	               <div><small>Тээврийн зардал үнэ: {gaali.transFare}</small></div>						   
				</div>				
				<div className="col">
						<div><small>Ачааны нэр: {gaali.goodsNM}</small></div>				   
						<div><small>Цэвэр жин: {gaali.netWGT}</small></div>				   
						<div><small>Бохир жин: {gaali.wgt}</small></div>
						<div><small>Х/нэгжийн код: {gaali.wgtUnit}</small></div>
						<div><small>Баглаа боодлын тоо: {gaali.qty}</small></div>
						<div><small>Баглаа боодлын нэгж: {gaali.qtyUnit}</small></div>
						<div><small>Аюултай барааны код: {gaali.dangGoodsCode}</small></div>						
						<div><small>БТКУС код: {gaali.hsCode}</small></div>					
						<div><small>Дипломат эсэх: {gaali.isDiplomat}</small></div>
                </div>
				<div className="col">				
	               <div><small>Барааны үнэ 1: {gaali.price1}</small></div>
	               <div><small>Валютын код 1: {gaali.priceCurr1}</small></div>   
	               <div><small>Барааны үнэ 2: {gaali.price2}</small></div>						   
	               <div><small>Валютын код 2: {gaali.priceCurr2}</small></div>   				   
	               <div><small>Барааны үнэ 3: {gaali.price3}</small></div>		
	               <div><small>Валютын код 3: {gaali.priceCurr3}</small></div>   				   
	               <div><small>Барааны үнэ 4: {gaali.price4}</small></div>		
	               <div><small>Валютын код 4: {gaali.priceCurr4}</small></div>   				   
	               <div><small>Барааны үнэ 5: {gaali.price5}</small></div>						   
	               <div><small>Валютын код 5: {gaali.priceCurr5}</small></div>   
                </div> 

                <div className="col">
                    <small>
                        <span className="text-muted">Илгээгч:</span>
					</small>
						<div><small>Дугаар: {gaali.shipperTel}</small></div>				   
						<div><small>Нэр: {gaali.shipperNM}</small></div>				   
						<div><small>Улсын код: {gaali.shipperCntryCD}</small></div>
						<div><small>Улсын нэр: {gaali.shipperCntryNM}</small></div>
						<div><small>Харяалал: {gaali.shipperNatinality}</small></div>
						<div><small>Хаяг: {gaali.shipperAddr}</small></div>
						<div><small>Регистр: {gaali.shipperReg}</small></div>						
                </div>
                <div className="col">
                    <small>
                        <span className="text-muted">Хүлээн авагч:</span>
					</small>
						<div><small>Дугаар: {gaali.consigneeTel}</small></div>
						<div><small>Нэр: {gaali.consigneeNM}</small></div>   
						<div><small>Улсын код: {gaali.consigneeCntryCD}</small></div>
						<div><small>Улсын нэр: {gaali.consigneeCntryNM}</small></div>
						<div><small>Харяалал: {gaali.consigneeNatinality}</small></div>
						<div><small>Хаяг: {gaali.consigneeAddr}</small></div>
						<div><small>Регистр: {gaali.consigneeReg}</small></div>
                </div>
				<div className="col">
                    <small>
                        <span className="text-muted">Зуучлагч байгууллага</span>
					</small>
						<div><small>Дугаар: {gaali.compTel}</small></div>
						<div><small>Нэр: {gaali.compName}</small></div>				   
						<div><small>Хаяг: {gaali.compAddr}</small></div>
						<div><small>Регистр: {gaali.compRegister}</small></div>	
						<div><small>Илгээмж бүртгэгдсэн огноо: </small></div>
						<div><small>Илгээгч улсаас ачаа хөдөлсөн огноо: </small></div>
						<div><small>Цахим худалдааны төлбөрийн баримтын хуулбар: {gaali.ecommerceType}</small></div>
						<div><small>Цахим худалдааны линк: {gaali.ecommerceLink}</small></div>
                </div>
            </div>
        </li>
    );
}

export default ListItem; 